<template>
  <Modal class="news-visor default news-readers" ref="modal" :settings="modal" @close="newsClose">
    <div class="news-visor-title" slot="header">
      <p class="news-visor-icon">
        <iconic name="myspace" />
      </p>
    </div>
    <div class="news-visor-content" v-if="news && news._id">
      <div class="news-visor-newsTitle">{{ news.titleNews || "Sin titulo" }}</div>
      <div class="news-visor-list">
        <div class="news-visor-list-title">
          <div class="by_label">Noticia vista por</div>
          <div class="by_title_users">{{ totalReaders + " Usuarios" }}</div>
        </div>
        <table class="news-visor-table" width="100%">
          <NewsReadersVisorItem v-for="reader in readers" :log="reader" :key="reader._id" />
        </table>
        <div class="news-visor-list-more">
          <LoadMore :hasmore="hasMore" label="Cargar más" :loading="loading" @more="getMoreReaders" />
        </div>
      </div>
    </div>
    <div class="news-visor-loading" v-else>
      <iconic name="dots_loading" />
    </div>
  </Modal>
</template>

<script>
  import "./NewsVisor.scss";
  export default {
    components: {
      NewsReadersVisorItem: () => import("./NewsReadersVisorItem.vue"),
    },
    data: function() {
      return {
        error: null,
        readers: [],
        totalReaders: 0,
        modal: { opened: true, outClose: true },
        loading: true,
        page: 1,
      };
    },
    methods: {
      newsClose: function() {
        this.$store.state.news.NewsReadersVisor = null;
      },
      getReaders: async function() {
        this.loading = true;
        try {
          const host = `news/readers?page=${this.page}&news=${this.news._id}`;
          const { data } = await this.$api.get(`${host}`);
          this.readersLoaded(data);
        } catch (error) {
          this.$Swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      },
      readersLoaded: function(data) {
        if (!data.items || !data.items.length) {
          return;
        }

        if (Number(data.page) === 1) {
          this.readers = data.items;
          this.totalReaders = data.total;
        } else {
          this.readers.push(...data.items);
        }
      },
      getMoreReaders: async function() {
        this.page++;
        await this.getReaders();
      },
    },
    mounted: function() {
      this.readers = this.news.readers.readers;
      this.totalReaders = this.news.readers.total;
      this.page = 1;
      this.getReaders();
    },
    computed: {
      news: function() {
        return this.$store.state.news.NewsReadersVisor || {};
      },
      hasMore: function() {
        const more = this.readers.length < this.totalReaders;
        return more;
      },
    },
  };
</script>

<style lang="scss">
  .news-visor {
    &-list {
      position: relative;
    }
    &-newsTitle {
      padding: 0 $mpadding $mpadding $mpadding;
      font-family: $sec_font;
      font-size: 150%;
    }
    &-list-title {
      padding: 0 $mpadding $mpadding $mpadding;
      border-bottom: solid 1px $alto;
      display: flex;
      justify-content: space-between;
      .by_label {
        position: relative;
      }
    }
    &-icon {
      font-size: 120px;
      margin-top: 28px;
    }
    &-table {
      .item-td {
        padding: $mpadding/2 $mpadding/2;
      }
      .item-tr .item-td:first-child {
        padding-left: $mpadding;
      }
      .item-tr .item-td:last-child {
        padding-right: $mpadding;
        text-align: right;
      }
      .item-tr:not(:last-child) {
        border-bottom: 1px solid $alto;
      }
      .item-tr {
        &:hover .item-td {
          background-color: #f5f5f5;
        }
        .__name {
          font-weight: bold;
        }
      }
    }
    .load-more {
      border-top: solid 1px $alto;
    }
  }
</style>
